.button-container {
    margin: 0 auto;
    
    button:nth-child(1) {
        margin-right: 5px;
    }
    button:nth-child(2) {
        margin-left: 5px;
    } 

    button:disabled,
    button[disabled]{
        cursor: not-allowed;
    }
}